import { Link, useLocation, useNavigate } from "@remix-run/react";
import { RiMailLine, RiShoppingCartFill, RiUser3Line } from "react-icons/ri";
import type { Contact } from "~types/api/contact.types";
import type { Product } from "~types/api/product.types";
import type { SessionData } from "~types/app/session.types";

import { PriceWarningModal } from "~/components/modals/price-warning-modal";
import { Stock } from "~/components/Stock";
import { Badge } from "~/components/ui/badge";
import { Button } from "~/components/ui/button";
import { Card } from "~/components/ui/cards/card";
import { Counter } from "~/components/ui/forms/counter";
import { Switch } from "~/components/ui/forms/switch";
import { Image } from "~/components/ui/image";
import { Heading, Text } from "~/components/ui/typography";
import { useAddToCart } from "~/hooks/use-add-to-cart";
import { useComparator } from "~/hooks/use-comparator";
import { getCharacteristicValue } from "~/services/characteristics";
import {
  getProductBarredPrice,
  getProductFinalPrice,
  getProductPriceSuffix,
  getProductTags,
  getProductWcPrice,
  isProductPriceVisible,
  isProductQuantityDecimal,
} from "~/services/product";
import { formatCurrency } from "~/utils/formatters";

interface ProductCardProps {
  product: Product;
  comparator: SessionData["comparator"] | null;
  contact: Contact | null;
  className?: string;
}

export const ProductCard = ({
  product,
  comparator,
  contact,
  className,
  ...props
}: ProductCardProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    cartFetcher,
    isCartFetcherLoading,
    isCartFetcherDone,
    isPriceWarningModalOpen,
    closePriceWarningModal,
  } = useAddToCart(product);

  const {
    isInComparator,
    handleComparatorProductChange,
    isComparatorFetcherLoading,
    isComparatorFetcherFailed,
  } = useComparator({ comparator });

  const isUserConnected = !!contact?.id;
  const mainImage = product.images.sort((a, b) => a.position - b.position)[0] || null;
  const subheadingThumbnail = getCharacteristicValue(product, "sous-titre-vignette");
  const tags = getProductTags(product);

  return (
    <>
      <PriceWarningModal
        open={isPriceWarningModalOpen}
        setOpen={closePriceWarningModal}
        product={product}
      />
      <article className={className} {...props}>
        <Card className="relative flex h-full flex-col transition-shadow duration-200 hover:shadow-md">
          <div className="absolute left-0 top-6 flex min-w-20 items-center justify-center overflow-hidden rounded-r-lg border border-l-0 border-grey-100 bg-white px-3 py-0 text-xs font-medium text-grey-900">
            <Image
              src={product.brand?.images[0]?.url}
              alt={product.brand?.title || ""}
              className="h-14 object-contain"
              fallbackElement={<div>{product.brand?.title}</div>}
            />
          </div>

          {tags && tags.length > 0 ? (
            <div className="absolute right-0 top-6 flex flex-col items-end gap-1.5">
              {tags.map((tag) => (
                <Badge key={tag?.id} color={tag.color} className="rounded-r-none border-r-0">
                  {tag?.label}
                </Badge>
              ))}
            </div>
          ) : null}

          <Link
            to={`/${product.categories[0]?.slug}/${product.slug}`}
            className="flex grow flex-col"
          >
            <Image
              src={mainImage?.url}
              alt={product.title}
              className="mx-auto aspect-[16/13] h-40 object-contain"
            />

            <Heading as="h3" size="base" className="mt-4 leading-tight">
              {product.title}
            </Heading>

            <Text as="p" size="sm" muted className="mt-2 grow leading-tight">
              {subheadingThumbnail}
            </Text>

            <Text as="p" size="xs" muted className="mt-2">
              Réf. POwR : {product.reference}
            </Text>

            {isUserConnected ? (
              isProductPriceVisible({ product, contact }) && product.price ? (
                <div className="mt-2.5">
                  <div className="flex flex-wrap items-end justify-start gap-4">
                    <Text as="p" size="lg" bold className="leading-none">
                      {formatCurrency(getProductFinalPrice(product))}{" "}
                      <Text as="span" size="sm" bold={false} muted>
                        HT{getProductPriceSuffix(product)}
                      </Text>
                    </Text>

                    {getProductBarredPrice(product) ? (
                      <Text as="p" size="sm" muted className="relative w-max">
                        {formatCurrency(getProductBarredPrice(product))}{" "}
                        <span className="absolute -left-[5%] top-1/2 block h-[1px] w-[110%] -rotate-12 bg-grey-700/50" />
                      </Text>
                    ) : null}
                  </div>

                  {getProductWcPrice(product) ? (
                    <Text as="p" size="sm" muted className="mt-1">
                      Soit {formatCurrency(getProductWcPrice(product), 3)} HT / Wc
                    </Text>
                  ) : null}
                </div>
              ) : (
                <Text as="p" bold className="mt-2.5">
                  Prix sur demande
                </Text>
              )
            ) : null}
          </Link>

          {isUserConnected ? (
            <Stock stock={product.stocks[0]} product={product} className="mt-2" />
          ) : null}

          {isUserConnected ? (
            <div className="flex items-center justify-between gap-3 border-y border-grey-200 py-2">
              <label htmlFor="comparator-switch" className="block text-xs">
                Comparer ce produit
              </label>
              <Switch
                key={isComparatorFetcherFailed?.toString()}
                id="comparator-switch"
                label="Comparer ce produit"
                disabled={!!isComparatorFetcherLoading}
                defaultChecked={isInComparator(product.id) || false}
                onChange={(checked) => {
                  handleComparatorProductChange({
                    id: product.id,
                    categorySlug: product.categories[0]?.slug || "",
                    checked,
                  });
                }}
              />
            </div>
          ) : null}

          {isUserConnected ? (
            isProductPriceVisible({ product, contact }) ? (
              <cartFetcher.Form
                className="mt-4 flex flex-wrap gap-2"
                method="post"
                action="/cart/actions?/addItem"
              >
                <input type="hidden" name="productId" value={product.id} />
                <Counter
                  id={`${product.slug}-quantity`}
                  name="quantity"
                  defaultValue={1}
                  step={isProductQuantityDecimal(product) ? 0.001 : 1}
                  min={isProductQuantityDecimal(product) ? 0.001 : 1}
                  max={9999}
                  className="grow"
                />
                <Button
                  type="submit"
                  loadingState={
                    isCartFetcherLoading ? "loading" : isCartFetcherDone ? "success" : "idle"
                  }
                  successText="Ajouté au panier"
                  icon={<RiShoppingCartFill />}
                  className="grow"
                >
                  Ajouter au panier
                </Button>
              </cartFetcher.Form>
            ) : (
              <Button
                onClick={() =>
                  navigate(`/nous-contacter?subject=estimate&product-slug=${product.slug}`)
                }
                icon={<RiMailLine />}
                className="mt-4"
              >
                Nous consulter
              </Button>
            )
          ) : (
            <Button
              onClick={() => navigate(`/connexion?redirect=${location.pathname}`)}
              icon={<RiUser3Line />}
              className="mt-4"
            >
              Se connecter
            </Button>
          )}
        </Card>
      </article>
    </>
  );
};
